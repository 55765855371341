import { InviteStatus } from '@capturi/core'
import { Flex, Icon, Td, Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import * as React from 'react'
import { FaUserSlash } from 'react-icons/fa'
import { IconType } from 'react-icons/lib'
import { MdCheck, MdMailOutline } from 'react-icons/md'

type Props = {
  status: string
}

const icons: { [key: string]: IconType } = {
  [InviteStatus.accepted]: MdCheck,
  [InviteStatus.sent]: MdMailOutline,
  [InviteStatus.unknown]: FaUserSlash,
}

const getTitle = (status: string): string => {
  switch (status) {
    case InviteStatus.accepted:
      return t`Active user`
    case InviteStatus.sent:
      return t`Invited, but not yet active`
    case InviteStatus.unknown:
      return t`Not invited`
    default:
      return ''
  }
}

const InviteStatusCell: React.FC<Props> = ({ status }) => {
  const title = getTitle(status)

  return (
    <Td>
      <Tooltip hasArrow label={title} aria-label={title} placement="left">
        <Flex justify="center" alignItems="center" h="100%">
          <Icon as={icons[status]} width="4" height="4" color="gray.800" />
        </Flex>
      </Tooltip>
    </Td>
  )
}

export default InviteStatusCell
