import tracker from '@capturi/analytics'
import { CurrentUser } from '@capturi/core'
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React, { ReactElement, useState } from 'react'

import { PatchUser, UiRole, toUiRole } from '../hooks/useUserActions'
import { Team, UserWithDeletionData } from '../types'
import TableHeader from './TableHeader'
import {
  permissionTooltip,
  roleTooltip,
  statusTooltip,
  teamLeadTooltip,
  teamTooltip,
} from './TableHeaderTooltips'
import DeletionCell from './cells/DeletionCell'
import InviteStatusCell from './cells/InviteStatusCell'
import NameCell from './cells/NameCell'
import PermissionsCell from './cells/PermissionsCell'
import RoleCell from './cells/RoleCell'
import SettingsCell from './cells/SettingsCell'
import TeamCell from './cells/TeamCell'
import TeamLeadCell from './cells/TeamLeadCell'

type Props = {
  users: UserWithDeletionData[]
  teams: Team[]
  currentUser: CurrentUser
  patchUser: PatchUser
  inviteUser: (userUid: string, email: string) => Promise<void>
  unassignUser: (userUid: string) => Promise<void>
  cancelUserDeletion: (userUid: string) => Promise<void>
  changeRole: (userUid: string, newRole: UiRole) => Promise<void>
  changeTeamLeadTeams: (userUid: string, teams: Team[]) => Promise<void>
}
type SortKey = 'name' | 'role' | 'team' | 'status' | 'permissions' | 'teamLead'

const UsersTable: React.FC<Props> = ({
  users,
  currentUser,
  teams,
  patchUser,
  changeRole,
  changeTeamLeadTeams,
  inviteUser,
  unassignUser,
  cancelUserDeletion,
}) => {
  const [isDrawerIdOpen, setIsDrawerIdOpen] = useState('')

  const [sort, setSort] = useState<{
    key: SortKey
    direction: -1 | 1
  }>({
    key: 'name',
    direction: 1,
  })

  const sorted = users.sort((a, b) => {
    switch (sort.key) {
      case 'status':
        if (a.isScheduledForDeletion !== b.isScheduledForDeletion) {
          return (a.isScheduledForDeletion ? -1 : 1) * sort.direction
        }
        return a.inviteStatus.localeCompare(b.inviteStatus) * sort.direction
      case 'role':
        return a.role.localeCompare(b.role) * sort.direction
      case 'team':
        if (!a.team?.name) return sort.direction
        if (!b.team?.name) return sort.direction * -1
        return a.team.name.localeCompare(b.team.name) * sort.direction
      case 'teamLead':
        return (
          ((a.teamLeadTeams?.length ?? 0) - (b.teamLeadTeams?.length ?? 0)) *
          sort.direction
        )
      case 'permissions':
        return (
          (Object.values(a.permissions).filter(Boolean).length -
            Object.values(b.permissions).filter(Boolean).length) *
          sort.direction
        )
      default:
        return a.name.localeCompare(b.name) * sort.direction
    }
  })
  const renderHeader = (
    key: SortKey,
    name: string,
    tooltip?: React.ReactNode,
  ): ReactElement => {
    return (
      <TableHeader
        ariaLabel={name}
        tooltip={tooltip}
        onClick={() => {
          tracker.event('organizationPage_users_headerSort', {
            key: key,
            directions: sort.direction,
          })
          setSort({ key: key, direction: sort.direction === 1 ? -1 : 1 })
        }}
        isActive={key === sort.key}
        direction={sort.direction}
      >
        {name}
      </TableHeader>
    )
  }

  const RenderRow = (user: UserWithDeletionData): ReactElement => {
    const {
      email,
      id,
      name,
      profileImage,
      title,
      role,
      team,
      teamLeadTeams,
      inviteStatus,
      permissions,
      when,
      isScheduledForDeletion,
      lastTokenRefresh,
      mostRecentConversation,
    } = user

    const rowClickHandler = (
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ): void => {
      e.stopPropagation()
      if (
        (e.target as HTMLTableRowElement).tagName === 'TR' ||
        (e.target as HTMLTableRowElement).tagName === 'TD'
      )
        setIsDrawerIdOpen(id)
    }

    return (
      <Tr
        key={id}
        _hover={{ bgColor: 'gray.200' }}
        cursor="pointer"
        onClick={rowClickHandler}
      >
        <NameCell
          email={email}
          name={name}
          profileImage={profileImage}
          title={title}
          key={id}
          lastTokenRefresh={lastTokenRefresh}
          mostRecentConversation={mostRecentConversation}
        />
        <RoleCell
          role={toUiRole(role, permissions)}
          userUid={id}
          currentUserUid={currentUser.id}
          width="150px"
          changeRole={changeRole}
        />
        <PermissionsCell
          role={role}
          width="150px"
          userPermissions={permissions}
          userUid={id}
          currentUserUid={currentUser.id}
          patchUser={patchUser}
          userName={name}
        />
        <TeamCell
          team={team}
          teams={teams}
          userUid={id}
          currentUserUid={currentUser.id}
          patchUser={patchUser}
          name={name}
          width="200px"
        />
        <TeamLeadCell
          userUid={id}
          role={role}
          teamLeadTeams={teamLeadTeams || []}
          teams={teams}
          changeTeamLeadTeams={changeTeamLeadTeams}
          width="160px"
        />
        {isScheduledForDeletion && when ? (
          <DeletionCell when={when} />
        ) : (
          <InviteStatusCell status={inviteStatus} />
        )}
        <SettingsCell
          inviteStatus={inviteStatus}
          userUid={id}
          isScheduledForDeletion={user.isScheduledForDeletion !== false}
          teamUid={team?.uid}
          inviteUser={inviteUser}
          unassignUser={unassignUser}
          cancelUserDeletion={cancelUserDeletion}
          currentEmail={email}
          name={name}
          teamName={team?.name}
          patchUser={patchUser}
          title={title}
          setIsDrawerIdOpen={setIsDrawerIdOpen}
          isDrawerIdOpen={isDrawerIdOpen === id}
        />
      </Tr>
    )
  }

  return (
    <Table size="sm" width="100%" variant="bordered">
      <Thead>
        <Tr>
          <Th>{renderHeader('name', t`Name`)}</Th>
          <Th>{renderHeader('role', t`Role`, roleTooltip)}</Th>
          <Th>
            {renderHeader('permissions', t`Permissions`, permissionTooltip)}
          </Th>
          <Th>{renderHeader('team', t`Team`, i18n._(teamTooltip))}</Th>
          <Th>
            {renderHeader('teamLead', t` Team lead`, i18n._(teamLeadTooltip))}
          </Th>
          <Th>{renderHeader('status', t` Status`, statusTooltip)}</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>{sorted.map(RenderRow)}</Tbody>
    </Table>
  )
}

export default UsersTable
