import tracker from '@capturi/analytics'
import { Flex, IconButton, Td, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useCallback, useState } from 'react'
import { MdEdit } from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import {
  PatchUser,
  defaultPermissionsPerRole,
} from '../../hooks/useUserActions'
import { Role, UserPermissions } from '../../types'
import PermissionDrawer from '../PermissionDrawer'

type Props = {
  currentUserUid: string
  userUid: string
  patchUser: PatchUser
  userPermissions: Partial<UserPermissions>
  width?: string
  role: Role
  userName: string
}

const hasModifiedPermissions = (
  role: Role,
  permissions: Partial<UserPermissions>,
): boolean => {
  const rolePermissions = defaultPermissionsPerRole[role]

  if (!rolePermissions) return false

  return Object.entries(rolePermissions).some(
    ([rolPermission, val]) =>
      permissions[rolPermission as keyof UserPermissions] !== val,
  )
}

const PermissionsCell: React.FC<Props> = ({
  userPermissions,
  role,
  patchUser,
  userUid,
  width,
  userName,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { disableUserConfig } = useFeatureFlags()

  const modifiedPermissions = hasModifiedPermissions(role, userPermissions)

  const handleOpen = useCallback((): void => {
    tracker.event('organizationPage_openPermissionDrawer')
    setIsOpen(true)
  }, [])

  const handleClose = useCallback((): void => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <Td width={width}>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text color={disableUserConfig ? 'gray.400' : 'text'}>
            {modifiedPermissions ? t`Limited` : t`Default`}
          </Text>
          <IconButton
            ml={2}
            icon={<MdEdit />}
            aria-label={t`Edit permissions`}
            onClick={handleOpen}
            isDisabled={disableUserConfig}
          />
        </Flex>
        <PermissionDrawer
          role={role}
          userPermissions={userPermissions}
          userUid={userUid}
          isOpen={isOpen}
          onClose={handleClose}
          patchUser={patchUser}
          userName={userName}
        />
      </Td>
    </>
  )
}

export default PermissionsCell
