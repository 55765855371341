import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SlideFade,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { isBefore } from 'date-fns'
import UsersTable from 'pages/Organization/components/UsersTable'
import { useDeletedUsers } from 'pages/Organization/hooks/useDeletedUserActions'
import { useUsers } from 'pages/Organization/hooks/useUsers'
import React, { useState } from 'react'
import { MdClose, MdInfoOutline, MdSearch } from 'react-icons/md'
import useSWR from 'swr'

import AgentsTable from '../../components/AgentsTable'
import { TableWrapper, TableWrapperHeader } from '../../components/TableWrapper'
import CreateUserModal from '../../components/modals/CreateUserModal'
import { useUserActions } from '../../hooks/useUserActions'
import { Team, UserPermissions, UserWithDeletionData } from '../../types'
import Filter, { DateFilterKey } from '../Filter'

const Users: React.FC = () => {
  const { hideInviteOptions } = useFeatureFlags()
  const currentUser = useCurrentUser()
  const { data: teams } = useSWR<Team[]>('authentication/organization/teams')

  const {
    patchUser,
    changeRole,
    changeTeamLeadTeams,
    inviteUser,
    unassignUser,
  } = useUserActions()

  const { cancelUserDeletion } = useDeletedUsers()

  const { users } = useUsers()
  const [openCreateUserModal] = useModal(CreateUserModal)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [dateFilter, setDateFilter] = useState<{
    key: DateFilterKey
    date: Date
  } | null>(null)
  if (!(users && teams)) return null

  const filterFunction = (u: UserWithDeletionData): boolean => {
    if (u.inviteStatus === 'unknown') return false

    if (dateFilter?.key === 'mostRecentConversation') {
      if (u.mostRecentConversation == null) return false
      return isBefore(u.mostRecentConversation, dateFilter.date)
    }
    if (dateFilter?.key === 'lastTokenRefresh') {
      if (u.lastTokenRefresh == null) return false
      return isBefore(u.lastTokenRefresh, dateFilter.date)
    }

    if (u.name.toLowerCase().includes(searchTerm.toLowerCase())) return true
    if (u.email.toLowerCase().includes(searchTerm.toLowerCase())) return true
    if (u.role.includes(searchTerm)) return true
    if (u.team?.name.toLowerCase().includes(searchTerm.toLowerCase()))
      return true
    if (u.permissions[searchTerm as keyof UserPermissions]) return true

    return false
  }

  const agentFilterFunction = (u: UserWithDeletionData): boolean => {
    if (dateFilter?.key === 'mostRecentConversation') {
      if (u.mostRecentConversation == null) return false
      return isBefore(u.mostRecentConversation, dateFilter.date)
    }
    if (dateFilter?.key === 'lastTokenRefresh') {
      if (u.lastTokenRefresh == null) return false
      return isBefore(u.lastTokenRefresh, dateFilter.date)
    }

    if (u.inviteStatus !== 'unknown') return false
    if (u.name.toLowerCase().includes(searchTerm.toLowerCase())) return true
    if (u.email.toLowerCase().includes(searchTerm.toLowerCase())) return true
    if (u.team?.name.toLowerCase().includes(searchTerm.toLowerCase()))
      return true

    return false
  }

  const activeUsers = users.filter(filterFunction)

  const activeAgents =
    activeUsers.length === 0 ? users.filter(agentFilterFunction) : []

  return (
    <Box>
      <TableWrapper>
        <TableWrapperHeader
          title={t`Users`}
          tooltip={t`People who have access to Capturi`}
          icon={MdInfoOutline}
        >
          <Filter
            setDateFilter={setDateFilter}
            dateFilter={dateFilter}
            filterOptions={['lastTokenRefresh', 'mostRecentConversation']}
          />

          <InputGroup width="30%" maxW="200px" size="sm">
            <InputLeftElement pointerEvents="none">
              <Icon fontSize="14px" as={MdSearch} />
            </InputLeftElement>
            <Input
              placeholder={t`Search...`}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
              value={searchTerm}
              borderColor="gray.200"
              borderRadius={4}
            />
            {searchTerm && searchTerm.length > 0 ? (
              <InputRightElement cursor="pointer">
                <Icon
                  fontSize="14px"
                  as={MdClose}
                  onClick={() => setSearchTerm('')}
                />
              </InputRightElement>
            ) : null}
          </InputGroup>
          {!hideInviteOptions && (
            <Button primary onClick={() => openCreateUserModal()}>
              <Trans>Create user</Trans>
            </Button>
          )}
        </TableWrapperHeader>
        {activeUsers.length > 0 ? (
          <UsersTable
            users={activeUsers}
            currentUser={currentUser}
            teams={teams}
            inviteUser={inviteUser}
            patchUser={patchUser}
            unassignUser={unassignUser}
            cancelUserDeletion={cancelUserDeletion}
            changeRole={changeRole}
            changeTeamLeadTeams={changeTeamLeadTeams}
          />
        ) : (
          <Flex justifyContent="center" my="4">
            <Text fontSize="lg">
              <Trans>No users found</Trans>
            </Text>
          </Flex>
        )}
      </TableWrapper>

      <SlideFade
        in={activeUsers.length === 0 && activeAgents.length >= 1}
        offsetY="20px"
      >
        <Box mt="20">
          <Flex justifyContent="center" my="4">
            <Text fontSize="lg">
              <Trans>But we found theses agents instead ❤️</Trans>
            </Text>
          </Flex>

          <TableWrapper>
            <TableWrapperHeader title={t`Agents`} />
            <AgentsTable
              agents={activeAgents}
              currentUser={currentUser}
              teams={teams}
              inviteUser={inviteUser}
              patchUser={patchUser}
              unassignUser={unassignUser}
              cancelUserDeletion={cancelUserDeletion}
            />
          </TableWrapper>
        </Box>
      </SlideFade>
    </Box>
  )
}

export default Users
