import { Flex, Icon, Td, Tooltip } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import * as React from 'react'
import { BiWindowClose } from 'react-icons/bi'

type Props = {
  when: Date
}

const DeletionCell: React.FC<Props> = ({ when }) => {
  return (
    <Td>
      <Tooltip
        hasArrow
        aria-label={t`Pending deletion: ${i18n.date(when, {
          dateStyle: 'long',
        })}`}
        label={t`Pending deletion: ${i18n.date(when, {
          dateStyle: 'long',
        })}`}
        placement="left"
      >
        <Flex justify="center" alignItems="center" h="100%">
          <Icon as={BiWindowClose} width="4" height="4" color="gray.800" />
        </Flex>
      </Tooltip>
    </Td>
  )
}

export default DeletionCell
