import { PermissionRow } from '@capturi/core'
import { t } from '@lingui/macro'
import type { UserPermissions } from 'pages/Organization/types'
export const getCrudPermissions = ({
  defaultRolePermissions,
  permissions,
  enableText,
}: {
  defaultRolePermissions: Partial<UserPermissions>
  permissions: UserPermissions
  enableText: boolean
  enableMasterTracker: boolean
}): PermissionRow[] => {
  const rows: PermissionRow[] = [
    {
      name: t`Dashboard`,
      key: 'editDashboard',
      tooltip: t`Permission to create, edit and delete dashboards. N.B. Only users with this permission can access 'non-shared' dashboards.`,
      disabled:
        permissions.editEverything ||
        defaultRolePermissions?.editDashboard == null, // if user can editEverything, disable editing of editDashboard
      hidden: false,
    },
    {
      name: t`Trackers`,
      key: 'editTracker',
      tooltip: t`Permission to create, edit and delete trackers.`,
      disabled:
        permissions.editEverything ||
        defaultRolePermissions?.editTracker == null, // if user can editEverything, disable editing of editTracker
      hidden: false,
    },
    {
      name: t`Segments`,
      key: 'editSegment',
      tooltip: t`Permission to create, edit and delete segments.`,
      disabled:
        permissions.editEverything ||
        defaultRolePermissions?.editSegment == null, // if user can editEverything, disable editing of editSegment
      hidden: false,
    },
    {
      name: t`Scores`,
      key: 'editScore',
      tooltip: t`Permission to create, edit and delete scores.`,
      disabled:
        permissions.editEverything || defaultRolePermissions?.editScore == null, // if user can editEverything, disable editing of editScore
      hidden: enableText,
    },
    {
      name: t`Library`,
      key: 'editLibrary',
      tooltip: t`Permission to create, edit, delete library playlists and add snippets to the library.`,
      disabled:
        permissions.editEverything ||
        defaultRolePermissions?.editLibrary == null, // if user can editEverything, disable editing of editLibrary
      hidden: enableText,
    },
  ]

  return rows.filter((d) => !d.hidden)
}
