import tracker from '@capturi/analytics'
import { PermissionRow, applyPermissionsRules } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useToast } from '@capturi/ui-components'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Switch,
  Table,
  Tbody,
  Td,
  Tooltip,
  Tr,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ReactElement, useEffect, useState } from 'react'
import { MdInfoOutline } from 'react-icons/md'
import {
  PatchUser,
  defaultPermissionsPerRole,
  defaultUserPermissions,
  toUiRole,
} from '../../hooks/useUserActions'
import { Role, UserPermissions } from '../../types'
import Header from './Header'
import { getCrudPermissions } from './permissionsCrud'
import { getOtherPermissions } from './permissionsOther'

type Props = {
  userUid: string
  userName: string
  onClose: () => void
  userPermissions: Partial<UserPermissions>
  role: Role
  isOpen: boolean
  patchUser: PatchUser
}

const PermissionDrawer: React.FC<Props> = ({
  userUid,
  onClose,
  isOpen,
  userPermissions,
  patchUser,
  role,
  userName,
}) => {
  const [permissions, setPermissions] = useState<UserPermissions>({
    ...defaultUserPermissions,
    ...userPermissions,
  })
  const uiRole = toUiRole(role, permissions)

  const { enableMasterTracker, enableText } = useFeatureFlags()

  useEffect(() => {
    setPermissions({ ...defaultUserPermissions, ...userPermissions })
  }, [userPermissions])

  const toast = useToast()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const defaultRolePermissions = defaultPermissionsPerRole[uiRole]

  const crudPermissions = getCrudPermissions({
    defaultRolePermissions,
    enableText,
    permissions,
    enableMasterTracker,
  })

  const otherPermissions = getOtherPermissions({
    defaultRolePermissions,
    permissions,
    enableMasterTracker,
    enableText,
  })

  const handleChange = async (
    key: keyof UserPermissions,
    value: boolean,
    userPermissions: UserPermissions,
  ): Promise<void> => {
    try {
      setInProgress(true)
      tracker.event('organizationPage_permissionChanged', {
        permission: key,
        value: value,
      })

      await patchUser(userUid, {
        permissions: userPermissions,
      })
      toast({
        title: t`Wuhuu, user updated`,
        status: 'success',
      })
    } catch (error) {
      let errMsg: null | string = null
      if (error instanceof Error) {
        errMsg = error.message
      }

      toast({
        title: t`Could not update the user's permission`,
        description: errMsg,
        status: 'error',
      })
    } finally {
      setInProgress(false)
    }
  }

  const togglePermission = (
    key: keyof UserPermissions,
    value: boolean,
  ): void => {
    const userPermissions = applyPermissionsRules(permissions, key, value)
    setPermissions(userPermissions)
    handleChange(key, value, userPermissions)
  }

  const renderRow = ({
    key,
    name,
    tooltip,
    disabled = undefined,
  }: PermissionRow): ReactElement => {
    return (
      <Tr key={key}>
        <Td w="100%" pl={0} py={2}>
          {name}
          <Tooltip hasArrow label={tooltip}>
            <chakra.span ml={1}>
              <Icon as={MdInfoOutline} />
            </chakra.span>
          </Tooltip>
        </Td>
        <Td pr={0} py={2}>
          <Tooltip
            isDisabled={!disabled}
            hasArrow
            label={t`This role can't have this permission`}
            placement="left"
          >
            <chakra.span ml={1}>
              <Switch
                isDisabled={inProgress || disabled}
                onChange={(e) => togglePermission(key, e.currentTarget.checked)}
                isChecked={permissions[key]}
              />
            </chakra.span>
          </Tooltip>
        </Td>
      </Tr>
    )
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottom="1px"
          borderBottomColor="gray.200"
          lineHeight={1.4}
          display="flex"
          alignItems="center"
          pr={10}
          mb={4}
        >
          <Trans>Permissions for {userName}</Trans>
        </DrawerHeader>
        <DrawerBody px={4}>
          <Box p={2}>
            <Header>
              <Trans>Create, edit and delete</Trans>
            </Header>

            <Table size="md" mb={4}>
              <Tbody>{crudPermissions.map(renderRow)}</Tbody>
            </Table>
            <Header>
              <Trans>Other functionalities</Trans>
            </Header>

            <Table size="md" mb={4}>
              <Tbody>{otherPermissions.map(renderRow)}</Tbody>
            </Table>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default PermissionDrawer
