import { Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'

type Props = { children: ReactElement }

const Header: React.FC<Props> = ({ children }) => {
  return (
    <Text py="2" fontWeight="medium" fontSize="md">
      {children}
    </Text>
  )
}

export default Header
